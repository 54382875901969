window.addEventListener('DOMContentLoaded', () => {

    var urlParams = new URLSearchParams(window.location.search);
    var hasSearched = urlParams.has('search');

    if (hasSearched) {
        document.querySelectorAll('.text-white').forEach(el => el.classList.remove('text-white'));
        document.querySelectorAll('.search-box').forEach(el => el.classList.remove('search-box'));
        document.querySelectorAll('.search-component-text, .flowpack-searchplugin-search > form').forEach(el => el.classList.add('search-box'));
        document.querySelectorAll('.search-column > .column-image').forEach(el => el.style['background-image'] = 'unset');
    }

    document.querySelectorAll('#mainContent section').forEach((el) => {
        if (!el.querySelector('.adam__modal')) {
            el.classList.add('section--fade');
            var observer = new IntersectionObserver((entries) => {
                if (entries[0].intersectionRatio > 0) {
                    el.classList.remove('section--fade');
                    el.classList.add('section--unfade');
                }
            }, { threshold: [0, 1] });
            observer.observe(el);
        }
    });
});